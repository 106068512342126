import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId} from '../../utils/gaia';
import ToolBar from '../Components/toolbar'
import classnames from 'classnames';
import Buttontb from '../Components/Button'
import moment from 'moment';


import {TabPane, TabContent,Nav, NavItem,NavLink } from 'reactstrap';
import { Images } from '../../utils/Images';
 
const Transactions = (props) => {
  const navigate = useNavigate();
  const [tnx, setTnx] = useState([])
  const [subs, setSubs] = useState([])
  const [claims, setClaims] = useState([])
  const [redeem, setRedeem] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [activeTab, setActiveTab] = useState('Transactions')

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadTransactions()
    }
  },[])

  const toggle = (tab) =>{
    setActiveTab(tab);
  }

  const formatDate = (date) =>{
    return moment(date).format("YYYY-MM-DD h:mma")
  }

  const loadTransactions = (evt) =>{
    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
    Axios.post(`${APIHOST}apian/wphook/transactions`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setTnx(data.transactions.data)
        setSubs(data.subs)
        setClaims(data.claims)
        setRedeem(data.redeem.data)
        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  } 


    return (
      <>
      <div className="container no-pad">
        <ToolBar link="transactions" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <div id="transactions">
          <Nav tabs className='nav-fill'>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'Transactions' })}  onClick={() => { toggle('Transactions'); }}>Transactions</NavLink>
            </NavItem>
            {/*<NavItem>
              <NavLink className={classnames({ active: activeTab === 'Subscriptions' })}  onClick={() => { toggle('Subscriptions'); }}>Subscriptions</NavLink>
        </NavItem>*/}
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'Redeem' })}  onClick={() => { toggle('Redeem'); }}>Redeem</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === 'Claims' })}  onClick={() => { toggle('Claims'); }}>Claims</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="Transactions">
            <div className="row m-0">
            {tnx.length == 0 ?
              <div className="empty-list">No Transactions available.</div>:
                <>
                {tnx.map(t=>(
                  <div class="table-item">
                    <div class="item-tit d-flex">
                      <h2>{t.ref_code}</h2>
                      <h5 class="date-time">{formatDate(tnx.created_at)}</h5>
                  </div>
                  <div class="item-desc d-flex align-items-end justify-content-between">
                    <table class="w-100">
                      <tr>
                        <td>Transaction Status</td>
                        <td class={t.status}>{t.status}</td>
                      </tr>
                      <tr>
                        <td>RECEIPT NUMBER</td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>TRANSACTION BILL</td>
                        <td>{t.real_total}</td>
                      </tr>
                      <tr>
                        <td>{`${t.tnx_type} EARNED`}</td>
                        <td>{`${t.tnx_type == "cashback" ? '$' : ''} ${parseFloat(t.tnx_rwd_val) + parseFloat(t.tnx_rwd_val_ser)}`}</td>
                      </tr>
                      <tr>
                        <td>WALLET USED</td>
                        <td>{t.discount}</td>
                      </tr>
                      <tr>
                        <td>VOUCHER VALUE</td>
                        <td>{t.rwd_discount}</td>
                      </tr>
                      <tr>
                        <td>FINAL BILL</td>
                        <td>{parseFloat(t.amount_se) + parseFloat(t.amount_pr)}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                ))}
                </>}
              </div>
            </TabPane>
            <TabPane tabId="Subscriptions">
            {subs.length == 0 ?
              <div className="empty-list">No Subscriptions available.</div>:
              <>
                {subs.map(s=>(
                  <div className='t-item'>
                    <div className='t-desc'>
                      <h4>{s.title} subscription</h4>
                      <p><strong>Date: </strong>{formatDate(s.created_at)}</p>
                      <p><strong>Validity:</strong> {s.start_date} - {s.end_date}</p>
                      <p><strong>Payment Method: </strong>{s.channel}</p>
                      <p><strong>Status: </strong>{s.status == 1 ? "Active" : "Inactive"}</p>
                    </div>
                    <div className='t-cost'>-S${s.cost}</div>
                  </div>
                ))}
              </>
            }
            </TabPane>
            <TabPane tabId="Redeem">
            {redeem.length == 0 ?
              <div className="empty-list">No Redemptions available.</div>:
              <>
                {redeem.map(r=>(
                  <div class="table-item">
                  <div class="item-tit d-flex">
                    <h2>{r.id}</h2>
                    <h5 class="date-time">{formatDate(r.modified_at)}</h5>
                </div>
                <div class="item-desc d-flex align-items-end justify-content-between">
                  <table class="w-100">
                    <tr>
                      <td>Transaction Status</td>
                      <td class={r.status == 1 ? "completed" : "reversed"}>{r.status == 1 ? "completed" : "cancelled"}</td>
                    </tr>
                    <tr>
                      <td>Wallet Amount</td>
                      <td>${r.wlt}</td>
                    </tr>
                    {r.rwds.length > 0 && <tr>
                      <td>Rewards</td>
                      <td>{r.rwds.map(rwd =>(
                        <p>{`- ${rwd}`}</p>
                      ))}</td>
                    </tr>}
                    {r.bdls.length > 0 && <tr>
                      <td>Packages</td>
                      <td>{r.bdls.map(bdl =>(
                        <p>{`- ${bdl}`}</p>
                      ))}</td>
                    </tr>}
                    {r.pkgs.length > 0 && <tr>
                      <td>Bundles</td>
                      <td>{r.pkgs.map(pkg =>(
                        <p>{`- ${pkg}`}</p>
                      ))}</td>
                    </tr>}
                    <tr>
                      <td>Outlet</td>
                      <td>{r.outlet_used}</td>
                    </tr>
                  </table>
                </div>
              </div>
                ))}
              </>
            }
            </TabPane>
            <TabPane tabId="Claims">
            {claims.length == 0 ?
              <div className="empty-list">No records available.</div>:
              <>
                {claims.map(c=>(
                  <div className='t-item claims'>
                    <div className=''>
                      <h4>{c.title}</h4>
                      <p><strong>Date: </strong>{formatDate(c.modified_at)}</p>
                      <p><strong>Outlet: </strong>{c.outlet_used}</p>
                    </div>
                  </div>
                ))}
              </>
            }
            </TabPane>
          </TabContent>
        </div>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadTransactions}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
    </>
    );
}

export default Transactions;
